@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: myFont;
  src: url(/static/media/PixelCraft-2Odxo.3796b4ea.otf);
}
:root {
  --max-width: 1550px;
}
body {
  margin: 0;
  font-family: myFont !important;
  box-sizing: border-box;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.g-8 {
  grid-gap: 8px;
  gap: 8px;
}
.ml-4 {
  margin-left: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-26 {
  margin-left: 26px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-6 {
  margin-top: 6px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mr-16 {
  margin-right: 16px;
}
.ml-auto {
  margin-left: auto;
}
.mt-32 {
  margin-top: 32px;
}
.mt-26 {
  margin-top: 26px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-94 {
  margin-bottom: 94px;
}
.gap-40 {
  grid-gap: 40px;
  gap: 40px;
}
.g-6 {
  grid-gap: 6px;
  gap: 6px;
}

.pt-8 {
  padding-top: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pl-1rem {
  padding-left: 1rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-weight: bold;
}
.text-12 {
  font-size: 12px !important;
}
.hidden {
  overflow: hidden !important;
}
.text-white {
  color: white !important;
}

.rsm-p-8 {
  /* background: url('./Assets/LandingPage/Popup.png') no-repeat center center/contain;
  background-size: 643px 415px; */
  border-style: solid;
  border-width: 10px;
  border-image: url(/static/media/borderFrame.b2334dc1.png) 42 stretch;
  border-image-width: 25px;
  background: #ffd554;
  background-clip: padding-box;
  /* padding:50px 70px !important; */
  z-index: 999999999 !important;
  position: relative;
}
.rsm-bg-white {
  background: transparent !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.lootContainer p,
.whatContainer p,
.newsContainer p,
.roadmapContainer p {
  font-family: "PT Sans", sans-serif !important;
  font-size: 14px;
}
.newsContainer p {
  font-family: "PT Sans", sans-serif !important;
  font-size: 16px !important;
}
.personal-page-modal {
  min-height: 50% !important;
  /* padding:20px */
}
.fontFamily {
  font-family: "PT Sans", sans-serif !important;
}
.text-10 {
  font-size: 10px !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.rsm-flex {
  /* transform:translateY(-92%) !important; */
  transition: all 0.2s ease-in-out !important;
}

